<template>
	<v-sheet flat>
		<h2 class="mb-4">Collaboration Statistics</h2>
		<v-text-field :label="$t('misc.search')" v-model="search"></v-text-field>
		<v-data-table disable-pagination :search="search" :headers="headers" :items="items"> 
		</v-data-table>
	</v-sheet>
</template>

<script>
import Vuex from "vuex";
export default {
	name: "Stats",
	data: () => {
		return {
			search: "",
		};
	},
	computed: {
		...Vuex.mapState({
			organisations: (state) => state.organisations.data,
			assessments: (state) => state.assessments.data,
			polls: (state) => state.polls.data,
			emails: (state) => state.emails.data,
		}),
        assessmentIds(){
            return Object.keys(this.assessments)
        },
		items() {
			const self = this;
			let rows = Object.keys(self.organisations).map((id) => {
				let row =  {};
                row.id = id;
                row.organisation = self.organisations[id].name;
                const assessment = Object.keys(self.assessments).find( a => {
                    return self.assessments[a].organisation == id;
                })
                row.assessment = assessment;
                let emails = Object.keys(self.emails).filter( e => self.emails[e].assessment == assessment );
                row.delegation_count = emails.length;
                let recipients = emails.flatMap( e => {
                    return self.emails[e].toUids;
                })
                recipients = recipients.filter( (a, i) => recipients.indexOf(a) == i );
                row.recipient_count = recipients.length;

                let polls = Object.keys(self.polls).filter( p => {
                    return self.polls[p].assessment == assessment;
                })
                row.poll_count = polls.length;
                return row;
			});
			return rows.filter((r) => r);
		},
		headers() {
			let headers = [
				{ text: "Organisation", value: "organisation" },
				{ text: "Delegations Sent", value: "delegation_count" },
				{ text: "Unique Recipients", value: "recipient_count" },
				{ text: "Polls Sent", value: "poll_count" },
			];
			return headers;
		},
	},
    watch: {
        assessmentIds: {
            immediate: true, 
            handler() {
                const self = this;
                self.assessmentIds.forEach( id => {
                    self.$store.dispatch("emails/fetchDelegationsByAssessment", id )
                    self.$store.dispatch("polls/fetchByAssessment", id )
                })
            }
        }
    }
};
</script>
